.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.content-header {
  background-color: #282c34;
  /* min-height: 10vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: 20; */
  color: white;
}

.app-content {
  background-color: #282c34;
  display: flex;
  /* min-height: 90vh; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: 17; */
  color: white;
}

html {
  min-height: 100vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.outer-container {
  /* min-height: 90vh; */
  /* margin-right: 0px;
  margin-left: 0px;

  padding-right: 0px;
  padding-left: 0px; */
}

.bg-body-tertiary {
  background-color: #282c34 !important;
  color: white !important;
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.bg-body-tertiary & .basic-navbar-nav & .nav-link {
  color: white !important;
}

body {
  height: 100vh;
  background-color: #282c34;
}

/* footer {
  position: absolute;
  bottom: 0;
  width: 100%;
} */

#root {
  position: relative;
  height: 100vh;
}

.ebay-banner {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 10vh; */
  /* background-color: white; */
  height: 220px;
  /* position: absolute;
  bottom: 0; */
}
